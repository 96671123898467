@import "mixins";

.label {
  color: var(--color-text-bright-tertiary);
  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
  line-height: 16px;
  position: relative;
}

.input {
  @include input-base;

  &[disabled] {
    opacity: 0.7;
  }

  &Small {
    height: 40px;
  }

  &Smaller {
    height: 32px;
  }

  &Light {
    background-color: var(--color-white);
  }

  &Empty {
    border-color: transparent;
    padding-left: 0;
    padding-right: 0;
  }

  &::placeholder {
    color: var(--color-text-bright-tertiary);
  }

  &Invalid {
    border-color: var(--color-alert-quinary);
  }

  &EndIcon {
    padding-right: 40px;
  }
}

.wrapper {
  position: relative;
  width: 100%;
}

.endIcon {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

.error {
  color: var(--color-alert-quinary);
  font-size: 12px;
  margin: 0;
}
