.ranges {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-radius: 8px;
  overflow: hidden;

  &Button {
    background-color: var(--color-main-quinary);
    border: none;
    cursor: pointer;
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--color-main-default);
    font-size: 14px;
    outline: none;
  }

  &ButtonActive {
    background-color: var(--color-main-default);
    color: var(--color-white);
  }
}

.picker {
  color: var(--color-main-default);

  &Inner {
    padding-left: 32px;
    position: relative;
  }

  &Inner svg {
    position: absolute;
    left: 0;
    top: 10px;
  }
}
