:root {
  --font-family: "Inter";

  --color-alert-default: #F7EDED;
  --color-alert-quinary: #cc5f5f;
  --color-main-default: #5570f1;
  --color-main-active: #5755ff;
  --color-main-quinary: #f4f6ff;
  --color-purple: #AF52DE;
  --color-main-quaternary: #e4e9ff;
  --color-warning-quaternary: #F6E0D5;
  --color-success-quinary: #edf7f0;
  --color-pink: #D732A8;
  --color-success-default: #519c66;
  --color-dark-primary: #0a0b10;
  --color-dark-secondary: #16171d;
  --color-orange: #DD5F20;
  --color-success-hover: #70C287;
  --color-success-active: #3D8F54;
  --color-alert-active: #A33129;
  --color-bright-tertiary: #ebebeb;
  --color-bright-quaternary: #b3b3b3;
  --color-bright-secondary: #f5f5f5;
  --color-brown: #A2845E;
  --color-teal: #30B0C7;
  --color-white: #fff;
  --color-utilities-measurement: #FAE1FA;

  --color-overlay-soft: rgb(255 255 255 / 55%);

  --color-text-dark-secondary: #d4d4de;
  --color-text-bright-primary: #0b0a10;
  --color-text-bright-secondary: #2f2d39;
  --color-text-bright-tertiary: #545260;
  --color-text-bright-quaternary: #7c7a85;
  --color-text-dark-quaternary: #888891;

  --border-radius-sm: 8px;
  --border-radius-md: 16px;

  --sidebar-width: 216px;
}
