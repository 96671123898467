.content {
  display: grid;
  grid-template-columns: 282px 1fr;
  column-gap: 16px;
  padding-left: 24px;
  padding-top: 24px;
}

.table {
  border-left: 1px solid var(--color-bright-secondary)
}

.checkbox {
  width: 16px;
  height: 16px;
}
