.operationType {
  color: var(--color-success-default);
  background-color: var(--color-success-quinary);
  border-radius: 40px;
  padding: 2px 6px;
}

.row {
  cursor: pointer;
}
