.header {
  border-bottom: 1px solid var(--color-bright-secondary);
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 12px 24px;

  &Title {
    color: var(--color-text-bright-primary);
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    margin: 0;
  }
}
