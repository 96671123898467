.wrapper {
  overflow-x: auto;
  padding: 16px 24px;
}

.column {
  border: 1px solid var(--color-bright-tertiary);
  border-radius: var(--border-radius-sm);
  padding: 16px;
  min-width: 280px;
  flex: 1;

  &Top {
    margin-bottom: 10px;
  }

  &Title {
    color: var(--color-text-bright-primary);
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    font-weight: 500;
  }

  &Counter {
    background-color: var(--color-bright-tertiary);
    border-radius: 40px;
    font-size: 10px;
    padding: 2px 4px;
  }
}

.item {
  border: 1px solid var(--color-bright-tertiary);
  border-radius: var(--border-radius-sm);
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 12px;
  cursor: pointer;
  margin-right: 2px;
  margin-top: 6px;
  margin-bottom: 6px;

  &Drag {
    background-color: var(--color-white);
    border-color: var(--color-main-default);
  }
}

.price {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.client {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.responsible {
  color: var(--color-text-bright-quaternary);
  font-weight: 500;
  line-height: 20px;
  font-size: 15px;
}
