.container {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  color: var(--color-text-bright-tertiary);
  font-size: 14px;
  font-weight: 400;

  &Select {
    background-color: var(--color-white);
    border-radius: var(--border-radius-sm);
    display: flex;
    height: 48px;
  }

  &SelectSmall {
    height: 32px;
  }

  &Indicator {
    display: grid;
    place-items: center;
    width: 32px;
    height: 100%;
  }

  &Invalid {
    border-color: var(--color-alert-quinary);
  }
}

.label {
  font-size: 12px;
}

.invalid {

}

.error {
  color: var(--color-alert-quinary);
  font-size: 12px;
  margin: 0;
}
