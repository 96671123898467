.form {

  &Description {
    color: var(--color-text-bright-primary);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
  }

  &Wrapper {
    padding: 24px;
  }

  &Footer {
    border-top: 1px solid var(--color-bright-secondary);
    padding: 12px 24px;
  }
}

.box {
  color: var(--color-text-bright-primary);
  background-color: var(--color-bright-secondary);
  border-radius: var(--border-radius-sm);
  padding: 16px;

  &Label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 12px;
    margin-top: 0;
  }

  &Value {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
  }
}
