.marker {
  width: 24px;
  height: 24px;
  border-radius: 8px;

  &--blue {
    background-color: var(--color-main-active);
  }

  &--black {
    background-color: var(--color-dark-primary);
  }

  &--purple {
    background-color: var(--color-purple);
  }

  &--pink {
    background-color: var(--color-pink);
  }

  &--orange {
    background-color: var(--color-orange);
  }

  &--light-green {
    background-color: var(--color-success-hover);
  }

  &--green {
    background-color: var(--color-success-active);
  }

  &--red {
    background-color: var(--color-alert-active);
  }

  &--brown {
    background-color: var(--color-brown);
  }

  &--teal {
    background-color: var(--color-teal);
  }
}

.btn {
  padding: 0;
  position: relative;
}

.icon {
  position: absolute;
  inset: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
