.wrapper {
  &Top {
    margin: 24px 24px 12px;
  }
}

.title {
  color: var(--color-text-bright-primary);
  font-weight: 500;
  font-size: 14px;
}
