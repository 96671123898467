.form {
  width: 626px;

  &Description {
    color: var(--color-text-bright-primary);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
  }

  &Title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }

  &Footer {
    border-top: 1px solid var(--color-bright-secondary);
    padding: 12px 24px;
  }

  &Wrapper {
    padding: 24px;
  }
}
