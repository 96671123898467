.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  &--md {
    gap: 16px;
  }

  &--sm {
    gap: 12px;
  }

  &--xxs {
    gap: 4px;
  }
}

@for $i from 1 through 12 {
  .grid-#{$i} {
    grid-column: span #{$i};
  }
}
