@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "normalize";
@import "vars";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: var(--font-family), sans-serif;
  font-size: 14px;
}

a {
  text-decoration: none;
}

.container {
  &--center {
    margin: 24px 173px;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-popper {
  box-shadow: 0px -6px 48px 0px rgba(9, 13, 17, 0.08);
}
.react-datepicker__triangle {
  display: none;
}

.ReactModalPortal {
  z-index: 999;
  position: relative;
}
.ReactModal__Overlay {
  background-color: rgb(19 19 19 / 75%) !important;
}
.ReactModal__Content {
  border-radius: var(--border-radius-md) !important;
}
.rs-dropdown {
  z-index: 999;
}
.rs-input {
  background-color: var(--color-bright-secondary);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--color-bright-secondary);
  height: 32px;
  &:hover,
  &:focus{
    outline: none !important;
  }
}

.text-primary {
  color: var(--color-main-default);
}
.text-secondary {
  color: var(--color-text-bright-quaternary)
}

.rs-picker-popup {
  z-index: 9999;
}

.ScrollbarsCustom-TrackY {
  background: none !important;
}
.ScrollbarsCustom-ThumbY {
  background-color: var(--color-bright-secondary) !important;
  margin-left: 6px;
  width: 5px !important;
}
.rs-drawer-content,
.rs-drawer-right, {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px ;
}
.rs-drawer-header {
  padding: 16px 12px;
}
.rs-drawer-body {
  padding: 0 24px 16px 24px;
}
.rs-drawer-header-close {
  display: none;
}
.rs-drawer-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.react-datepicker__header--time {
  display: none;
}
.react-datepicker-popper {
  z-index: 9999;
}
