.container {
  display: grid;
  grid-template-rows: 64px 1fr;
  height: 100%;
}

.content {
  display: grid;
  grid-template-columns: 282px 1fr;
  column-gap: 16px;
  padding-left: 24px;

  &Categories {
    height: 100%;
    padding-top: 24px
  }
}
