.products {
  &Label {
    font-weight: 500;
    font-size: 16px;
    margin-right: 24px;
  }

  input {
    width: 320px;
  }
}

.table {
  margin-top: 12px;

  &Cell input {
    width: 100px;
    height: 40px;
  }

  &Row:hover {
    background-color: transparent;
  }
}

.autocomplete {
  min-width: 320px;

  input {
    border-radius: 12px;
    height: 40px;
  }
}

.selectBtn {
  height: 20px !important;
}
