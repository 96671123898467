.container {
  display: grid;
  grid-template-rows: auto 48px;
  height: 100%;
}

.table {
  border-bottom: 1px solid var(--color-bright-secondary);

  &User {
    color: var(--color-dark-primary);
  }
}
