.search {
  display: flex;
  align-items: center;
  column-gap: 4px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: var(--border-radius-sm);
  background: var(--color-bright-secondary);

  input {
    padding: 0;
    background-color: transparent;
    border: 0;
    outline: none;
    width: 100%;
  }
}
