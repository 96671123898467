.calendarEntry {
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  background-color: var(--color-bright-secondary);
  border-color: var(--color-bright-quaternary);
  color: var(--color-text-bright-primary);
  overflow: hidden;

  &Inner {
    overflow: hidden;
    text-overflow: ellipsis;
  }


  &Blue {
    background-color: var(--color-main-quinary);
    border-color: var(--color-main-active);
    color: var(--color-main-active);
  }

  &Orange {
    background-color: var(--color-warning-quaternary);
    border-color: var(--color-orange);
    color: var(--color-orange);
  }

  &Pink {
    background-color: var(--color-utilities-measurement);
    border-color: var(--color-pink);
    color: var(--color-pink);
  }

  &Teal {
    background-color: var(--color-utilities-measurement);
    border-color: var(--color-pink);
    color: var(--color-pink);
  }
}

.flexHelper {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 2px;
}
