.page {
  &Content {
    display: grid;
    row-gap: 40px;
  }

  &Title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 12px;
  }
}

.infoBlock {
  background-color: var(--color-bright-secondary);
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  &Title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
  }

  &Price {
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
  }

  &PriceDanger {
    color: var(--color-alert-quinary);
  }

  &PriceSuccess {
    color: var(--color-success-default);
  }
}

.section {
  &Top {
    margin-bottom: 12px;
  }

  &Title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }
}
