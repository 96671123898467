.section {
  border-radius: 16px;
  border: 1px solid var(--color-bright-tertiary);
  display: grid;
  row-gap: 12px;
}

.padding-sm {
  padding: 12px;
}
.padding-md {
  padding: 16px;
}
.padding-lg {
  padding: 24px;
}
