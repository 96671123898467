.group {
  display: flex;

  * {
    //flex: 1;
  }
}

.sm {
  gap: 12px;
}
.md {
  gap: 16px;
}
