.container {
}

.form {
  display: grid;
  row-gap: 16px;
  grid-template-rows: 1fr 64px;
  height: 100%;

  &Top {
    display: grid;
    row-gap: 16px;
  }

  &Actions {
    border-top: 1px solid var(--color-bright-secondary);
    padding-left: 24px;
    padding-right: 24px;
  }

  &Title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 8px 0;
  }

  &Footer {
    margin-top: 16px;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.row {
  display: flex;
  align-items: center;
  column-gap: 24px;
}
