.scheduler {
  &Day {
    width: 200px;
  }

  input {
    width: 136px;
  }
}

.form {
  display: grid;
  row-gap: 16px;
  grid-template-rows: 1fr 64px;
  height: 100%;

  &Actions {
    border-top: 1px solid var(--color-bright-secondary);
    padding-left: 24px;
    padding-right: 24px;
  }
}

.title {
  color: var(--color-text-bright-primary);
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.footer {
  margin-top: 16px;
}
