.container {
  margin-top: 16px;
}

.title {
  color: var(--color-text-bright-primary);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 8px 0;
}

.footer {
  margin-top: 12px;
}
