.wrapper {
  background-color: var(--color-bright-secondary);
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
  border-radius: var(--border-radius-sm);
  color: var(--color-text-bright-secondary);
  border: 1px solid var(--color-bright-secondary);

  &Invalid {
    border-color: var(--color-alert-quinary);
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.label {
  color: var(--color-text-bright-tertiary);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 4px;
}

.container:global(.react-datepicker) {
  background-color: var(--color-white);
  border-radius: 0.25rem;
  border: 0;
  box-shadow: 0 0.25rem 1rem var(--color-shadow-card);
  font-family: inherit;
  font-weight: 400;
  user-select: none;
  cursor: pointer;

  & :global(.react-datepicker__header) {
    background-color: var(--color-white);
    border-bottom: thin solid var(--color-grey-30);
    font-weight: 500;
    padding: 0.25rem 0;
  }
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  padding: 0 0.25rem;

  &Title {
    font-size: 0.75rem;
    white-space: nowrap;
  }

  &Button {
    display: block;
    width: 40px;
    height: 40px;
  }
}




.day:global(.react-datepicker__day) {
  border: thin solid transparent;
  border-radius: 0.25rem;
  color: var(--color-black);
  transition: color 0.2s, background-color 0.2s, border-color 0.2s;

  &:hover,
  &:focus,
  &:global(.react-datepicker__day--keyboard-selected),
  &:global(.react-datepicker__day--in-selecting-range) {
    background-color: var(--color-primary-10);
    border-color: var(--color-primary-10);
    box-shadow: none;
    outline: 0;
  }

  &:global(.react-datepicker__day--today) {
    background-color: var(--color-white);
    border-color: var(--color-primary-40);
    color: var(--color-black);
    font-weight: 600;

    &:hover,
    &:focus {
      background-color: var(--color-primary-10);
    }
  }

  &:global(.react-datepicker__day--weekend) {
    color: var(--color-alert-quinary);
  }

  &:global(.react-datepicker__day--in-range),
  &:global(.react-datepicker__day--selected) {
    background-color: var(--color-main-active);
    border-color: var(--color-main-active);
    color: var(--color-white);
  }

  &:global(.react-datepicker__day--disabled) {
    opacity: 0.2;
    border: none;
  }
}

.error {
  color: var(--color-alert-quinary);
  font-size: 12px;
  margin: 4px 0 0;
}
