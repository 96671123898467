.button {
  --size-xxs: 20px;
  --size-xs: 24px;
  --size-sm: 32px;
  --size-md: 40px;
  --size-xl: 48px;

  background-color: transparent;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  padding: 0;
  color: inherit;
  display: grid;
  place-items: center;

  &[disabled] {
    cursor: default;
    opacity: 0.3;
  }

  &--stroke {
    border: 1px solid var(--color-bright-tertiary);
  }

  &--primary {
    background-color: var(--color-main-active);
  }

  &--dark {
    background-color: var(--color-dark-primary);
  }

  &--empty-dark {
    color: var(--color-dark-primary);
  }

  &--empty-primary {
    color: var(--color-main-active);
  }

  &--secondary {
    background-color: var(--color-bright-secondary);
  }

  &--size-xl {
    min-width: var(--size-xl);
    min-height: var(--size-xl);
  }

  &--size-xs {
    min-width: var(--size-xs);
    min-height: var(--size-xs);
  }

  &--size-xxs {
    min-width: var(--size-xxs);
    min-height: var(--size-xxs);
  }

  &--size-sm {
    min-width: var(--size-sm);
    min-height: var(--size-sm);
  }

  &--size-md {
    min-width: var(--size-md);
    min-height: var(--size-md);
  }
}
