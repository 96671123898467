.table {
  &Info {
    display: flex;
    align-items: center;
    column-gap: 6px;
  }

  &User {
    color: var(--color-text-bright-primary);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin: 0 0 4px;
  }

  &UserJob {
    color: var(--color-text-bright-quaternary);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    margin: 0;
  }
}
