.modal {
  width: 626px;
}

.description {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 12px;
}

.reasons {
  margin-bottom: 24px;

  &Item {
    font-size: 14px;
  }
}

.status {
  color: var(--color-text-bright-primary);
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 16px;

  &Item {
    display: inline-block;
    padding: 2px 6px;
    background-color: var(--color-bright-secondary);
    border-radius: 40px;
  }
}
