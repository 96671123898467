.container {
  display: grid;
  row-gap: 16px;
  padding-bottom: 16px;
  margin-top: 8px;
}

.top {
  margin-bottom: 24px;
}

.form {
  display: grid;
  row-gap: 16px;

  &Title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }
}

.accounts {
  padding: 0;
  margin: 0;
  list-style-type: none;
  height: 88px;
  margin-bottom: 16px;

  &Item {
    border-radius: 12px;
    padding: 24px;
    border: 1px solid var(--color-bright-tertiary);
  }

  &ItemTitle {
    color: var(--color-text-bright-primary);
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    font-weight: 500;
    margin: 0;
  }

  &ItemTitle span {
    color: var(--color-text-bright-quaternary);
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.fieldset {
  border-radius: 12px;
  padding: 32px 24px 24px;
  border: 1px solid var(--color-bright-tertiary);
}
