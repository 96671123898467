.modal {
  width: 1000px;
}

.jobs {
  display: grid;
  grid-template-columns: 220px 1fr;
  column-gap: 16px;
}

.nav {
  &List {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &Button {
    justify-content: start;
    text-align: left;
    white-space: normal;
    min-height: 32px;
    padding: 6px 10px;
  }
}
