.social {
  margin-top: 8px;

  &Item {
    background-color: var(--color-bright-secondary);
    border-radius: var(--border-radius-sm);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 8px 16px;
  }

  &Nickname {
    color: var(--color-text-bright-secondary);
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}
