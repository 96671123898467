.wrapper {
  display: grid;
  grid-template-rows: min-content auto 72px;
  height: 100%;

  &Top {
    border-bottom: 1px solid var(--color-bright-secondary);
    padding: 12px 24px 16px 24px;
  }

  &Content {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &Subdivision {
    margin: 0;
  }

  &Tabs {
    justify-content: start;
  }

  &SubmitBtn {
    width: 127px;
  }
}

.title {
  color: var(--color-main-default);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.title button {
  padding: 0;
  color: inherit;
}

.total {
  width: 100%;
  border-top: 1px solid var(--color-bright-secondary);
  padding-top: 12px;
  padding-bottom: 12px;

  &Item {
    border-right: 1px solid var(--color-bright-secondary);
    color: var(--color-text-bright-quaternary);
    flex-grow: 1;
    font-size: 12px;
    line-height: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 6px;
  }

  &Item:last-child {
    flex-grow: 0;
  }

  &Item p:first-child {
    color: var(--color-text-bright-primary);
    font-weight: 500;
    margin: 0;
  }
}

.grid {
  align-items: center;
  padding-top: 16px;
  height: auto;
}

.job {
  & div {
    width: max-content;
  }

  &Label {
    font-weight: 500;
    font-size: 16px;
    margin-right: 24px;
  }

  input {
    width: 320px;
  }
}


.tabs {
  &Btn {
    background-color: transparent;
    border-radius: 6px;
    border: 1px solid var(--color-bright-secondary);
    cursor: pointer;
    outline: none;
    line-height: 20px;
    font-weight: 400;
    padding: 2px 6px;
  }

  &BtnActive {
    border-color: var(--color-main-active);
    color: var(--color-main-active);
    font-weight: 500;
  }
}

.autocomplete {
  height: 32px;
}

.carWrapper {
  width: 186px;
}

.history {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    left: 7px;
    top: 12px;
    background-color: #494A50;
  }

  &Items {
    padding-top: 12px;
    padding-bottom: 12px;
  }


  &Item {
    border-bottom: 1px solid var(--color-bright-tertiary);
    padding-top: 12px;
    padding-bottom: 12px;
    margin-left: 24px;
    position: relative;
    font-size: 12px;
    line-height: 16px;
  }

  &Item::before {
    content: '';
    position: absolute;
    width: 16px;
    left: -24px;
    top: 8px;
    height: 16px;
    background-color: #494A50;
    border-radius: 50%;
  }

  &Time {
    color: var(--color-text-bright-primary);
    font-weight: 500;
  }

  &Comment {
    color: var(--color-text-bright-quaternary);
  }

  &Empty {
    color: var(--color-bright-quaternary);
    font-size: 12px;
    margin-top: 16px;
  }
}

.bottomRow {
  min-height: 68px;
}

.textRow {
  min-height: 32px;
}

.smallBtn {
  font-size: 12px;
  justify-content: start;
}

.modal {
  width: 1000px
}

.smallFormModal {
  width: 415px;
}
.agentFormModal {
  width: 415px;
}

.carList {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &Item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
