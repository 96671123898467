.container {
  overflow: hidden;
  user-select: none;
}

.row {
  border-bottom: 1px solid #f2f2f3;
  height: 100%;
}

.td {
  border-right: 1px solid #f2f2f3;
  position: relative;
  flex: 1;
  padding: 12px;
  margin-left: -1px;
  min-width: 50px;
  
  span {
    display: inline-block;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  &CreateBtn {
    position: absolute;
    inset: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }

  &First {
    flex: 2;
  }

  &Month {
    min-width: 70px;
    max-width: 70px;
    justify-content: center !important;
    padding: 0;
  }

  &Month span {
    transform: rotate(-90deg);
  }
}

.cell {
}
