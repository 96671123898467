.radioGroup {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  height: 100%;

  &Label {
    color: var(--color-text-bright-tertiary);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  &Items {
    display: flex;
    column-gap: 16px;
    height: 48px;
    padding-bottom: 4px;
    align-items: end;
  }
}

.error {
  color: var(--color-alert-quinary);
  font-size: 12px;
  margin: 0;
}
