.nav {
  padding-top: 24px;
  padding-left: 24px;

  &List {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &Item {
  }

  &Link {
    background-color: var(--color-main-quinary);
    border-radius: 8px;
    color: var(--color-main-default);
    display: block;
    padding: 6px 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    transition: background-color 0.1s ease-in-out;
  }

  &Link:hover {
    background-color: var(--color-main-quaternary);
  }
}
