.flex {
  display: flex;
}

.row-gap-zero {
  row-gap: 0;
}

.row-gap-sm {
  row-gap: 12px;
}

.row-gap-md {
  row-gap: 16px;
}

.row-gap-lg {
  row-gap: 24px;
}

.row-gap-xl {
  row-gap: 32px;
}

.row-gap-xs {
  row-gap: 8px;
}

.row-gap-xxs {
  row-gap: 4px;
}

.column-gap-zero {
  column-gap: 0;
}

.column-gap-xxs {
  column-gap: 4px;
}

.column-gap-xs {
  column-gap: 8px;
}

.column-gap-sm {
  column-gap: 12px;
}

.column-gap-md {
  column-gap: 16px;
}

.column-gap-lg {
  column-gap: 24px;
}

.fullWidth {
  width: 100%;
}
