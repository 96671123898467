.notes {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.note {
  &Text {
    color: var(--color-text-bright-primary);
    margin: 0;
    font-size: 14px;
    line-height: 20px;
  }
}

.empty {
  font-size: 12px;
}
