@import "@/style/mixins";

.statuses {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  width: max-content;

  &Btn {
    background-color: var(--color-main-quinary);
    border: none;
    color: var(--color-main-default);
    cursor: pointer;
    outline: none;
    font-size: 14px;
    line-height: 20px;
    padding: 2px 8px;
  }

  &Btn[disabled] {
    cursor: default;
  }

  &BtnGreen {
    background-color: var(--color-success-quinary);
    color: var(--color-success-default)
  }

  &BtnRed {
    background-color: var(--color-alert-default);
    color: var(--color-alert-quinary)
  }

  &BtnGray {
    background-color: var(--color-bright-secondary);
    color: var(--color-text-bright-primary)
  }

  &BtnActive {
    background-color: var(--color-main-default);
    color: var(--color-white)
  }

  &Right {
    column-gap: 4px;
  }

  &Right button {
    border-radius: 8px;
  }
}
