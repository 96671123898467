.container {
  display: flex;
  position: relative;
  border: 1px solid var(--color-bright-secondary);
  background-color: var(--color-bright-secondary);
  border-radius: var(--border-radius-sm);
  min-height: 58px;
  flex-direction: column;
}

.label {
  color: var(--color-text-bright-tertiary);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  display: block;
}

.textarea {
  background-color: transparent;
  border: none;
  color: var(--color-text-bright-tertiary);
  font-family: inherit;
  font-size: 14px;
  padding: 10px 16px 0;
  resize: none;
  line-height: 20px;
  transition: background var(--transition-duration),
    border var(--transition-duration), color var(--transition-duration);
  width: 100%;

  &::placeholder {
    color: var(--color-grey-30);
    font-family: inherit;
    font-size: inherit;
  }

  &:active,
  &:focus {
    //border: var(--px) solid var(--color-brand);
    outline: none;
  }

  &-disabled {
    background-color: var(--color-bg-border);
    border-color: var(--color-bg-border);
    color: var(--color-text-secondary);

    &::-webkit-resizer {
      display: none;
    }
  }

  &-invalid {
    border-color: var(--color-alert-danger);
  }
}

.chars {
  font-size: 10px;
  line-height: 12px;
  color: var(--color-text-dark-quaternary);
  padding: 8px 16px;

  &-disabled {
    display: none;
  }
}

.button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
