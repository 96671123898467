.breadcrumbs {
  &List {
    display: flex;
    column-gap: 4px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  &Item {
    display: flex;
    align-items: center;
  }

  &Link {
    display: flex;
    align-items: center;
    column-gap: 4px;
    color: var(--color-text-bright-secondary);
  }

  &Text {
    color: var(--color-main-default);
  }
}
