.container {
}

.form {
  display: grid;

  &Body {
    padding: 24px 24px 0 24px;
  }

  &Actions {
    border-top: 1px solid var(--color-bright-secondary);
    margin-top: 80px;
    padding: 12px 24px;
  }

  &Title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 12px 0
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.row {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.modal {
  width: 1000px;

  &Body {
    padding-bottom: 80px;
  }

  &Title {
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
    margin: 0
  }
}

.column {
  margin-bottom: 24px;
}
