.wrapper {
  overflow: auto;
  max-width: 100%;
  max-height: 80vh;
}

.header {
  position: sticky;
  top: 0;
  z-index: 10;

  &::after {
    border-bottom: var(--px) solid var(--color-bg-divider);
    content: "";
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.table {
  border-collapse: collapse;
  white-space: nowrap;
  width: 100%;
  font-size: 12px;
  line-height: 16px;

  thead {
    background-color: var(--color-bright-secondary);
  }

  thead .cell {
    border-right: 1px solid var(--color-bright-tertiary);
  }

  &Loader {
    background-color: var(--color-overlay-soft);
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
    position: absolute;
    z-index: 1;
  }

  .rs-dropdown {
    position: static !important;
  }
}

.row {
  border-bottom: 1px solid var(--color-bright-secondary);
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: var(--color-bright-secondary);
  }
}

.cell {
  background-color: inherit;
  color: var(--color-dark-primary);
  padding: 0 8px;
  border-right: 1px solid var(--color-bright-secondary);
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  height: 40px;

  &:first-child {
    padding-left: 24px;
  }

  &:last-child {
    padding-right: 24px;
  }

  &Inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.footer {
}

.emptyMessage {
  color: var(--color-text-bright-tertiary);
  margin: 24px;
  text-align: center;
  font-size: 14px;
}

.dropdown {
  position: absolute;

  li:not(:last-child) {
    border-bottom: 1px solid var(--color-bright-secondary);
  }

  &Button {
    padding: 0;
  }
}
