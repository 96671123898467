.table {
  &User {
    color: var(--color-dark-primary);
  }

  &Footer {
    padding-top: 24px;
    padding-left: 24px;
  }
}
