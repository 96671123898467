@import "@/components/UI/Input/mixins";

.container {
  color: var(--color-text-bright-tertiary);
  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  line-height: 16px;

  &FullWidth {
    width: 100%;
  }
}

.input {
  @include input-base;

  &Light {
    background-color: var(--color-white);
    border-color: transparent;
  }
}

.invalid {
  border-color: var(--color-alert-quinary);
}

.error {
  color: var(--color-alert-quinary);
  font-size: 12px;
  margin: 0;
}
