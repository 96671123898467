.wrapper {
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
  width: 320px;
  row-gap: 16px;
  min-width: 350px;

  &Email {
    color: var(--color-main-default)
  }

  &Title {
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
    margin: 0;
  }

  &Info {
    background-color: var(--color-main-quinary);
    border-radius: 8px;
    border-left: 2px solid var(--color-main-default);
    color: var(--color-text-bright-primary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 16px;
  }

  &Btn ,
  &ResetPasswordLink{
    font-size: 14px;
    white-space: nowrap;
    width: 50%;
  }

  &ResetPasswordLink {
    background-color: var(--color-bright-secondary);
    padding: 10px 16px;
    border-radius: 12px;
    color: var(--color-text-bright-secondary);
    height: 40px;
    display: grid;
    place-items: center;
  }

  &Description {
    color: var(--color-text-bright-primary);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
  }

  &Timer {
    color: var(--color-text-bright-secondary);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
  }
}

.error {
  color: var(--color-alert-quinary);
  text-align: center;
}
