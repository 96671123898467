.checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  column-gap: 8px;
  user-select: none;

  &Handle {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  &Label {
    color: var(--color-text-bright-primary);
    font-size: inherit;
    font-weight: 400;
    line-height: 24px;
  }

  &Box {
    align-items: center;
    background-color: var(--color-white);
    border: thin solid var(--color-bright-secondary);
    border-radius: 8px;
    color: var(--color-white);
    display: inline-flex;
    flex-shrink: 0;
    font-size: 1.375rem;
    height: 24px;
    transition: background-color 0.2s, border-color 0.2s;
    width: 24px;

    &-align {
      &-start {
        order: -1;
      }

      &-end {
        order: initial;
      }
    }
  }

  &:hover &Box,
  &Handle:focus ~ &Box {
    //background-color: var(--color-grey-20);
  }

  &Handle:checked ~ &Box {
    background-color: var(--color-main-default);
    border-color: var(--color-main-default);
  }

  &:hover &Handle:checked ~ &Box,
  &Handle:checked:focus ~ &Box {
    background-color: var(--color-main-default);
  }
}
