.radio {
  align-items: center;
  display: inline-flex;
  cursor: pointer;

  &Handle {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  &Box {
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--color-bright-secondary);
    display: flex;
    height: 24px;
    justify-content: center;
    transition: background-color 0.2s, border-color 0.2s;
    width: 24px;

    &::before {
      background-color: transparent;
      border-radius: inherit;
      content: "";
      height: 50%;
      width: 50%;
    }

    &-align {
      &-start {
        margin-right: 0.5rem;
        order: -1;
      }

      &-end {
        margin-left: 0.5rem;
        order: initial;
      }
    }
  }

  &:hover &Box,
  &Handle:focus + &Box {
    background-color: var(--color-bright-secondary);
    border-color: var(--color-bright-quaternary);
  }

  &Handle:checked + &Box {
    background-color: var(--color-main-default);
    border-color: var(--color-main-default);
  }
  &Handle:checked + &Box::before {
    background-color: var(--color-white);
  }
}
