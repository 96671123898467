.modal {
  width: 1000px;
}

.items {
  display: grid;
  row-gap: 16px;

  .item {
    display: flex;
    flex-direction: column;
    font-weight: 400;

    &Label {
      color: var(--color-text-bright-tertiary);
      font-size: 10px;
      line-height: 16px;
      text-transform: uppercase;
    }

    &Value {
      color: var(--color-text-bright-primary);
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }
  }
}

.createdBy {
  margin-left: auto;
  position: absolute;
  right: 6rem;
  display: inline-block;
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}
