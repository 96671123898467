.range {
  --range-progress: 0;

  -webkit-appearance: none;
  appearance: none;
  background: url("./assets/audioroad.svg") repeat-x;
  cursor: pointer;
  height: 20px;
  position: relative;
  width: 100%;

  &::before {
    content: '';
    height: initial;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: url("./assets/bg.svg") repeat-x;
    width: var(--range-progress);
  }

  &::-moz-range-track {
    position: relative;
    background: #ccc;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    cursor: pointer;
  }

  &::-moz-range-progress {
    background: #f50;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    height: 2px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    position: relative;
  }

  &::-moz-range-thumb {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #f50;
    cursor: pointer;
    border: transparent;
    position: relative;
  }
}

.visualizer {
  height: 20px;
  width: 498px;
}
