.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0
}

.socialMediaItems {
  padding-top: 24px;
}

.error {
  color: var(--color-alert-quinary);
  font-size: 12px;
  margin: 8px 0 0 0;
}
