.container {
  padding: 12px 24px;
}

.title {
  color: var(--color-main-default);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.infoList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  color: var(--color-text-bright-secondary);
  column-gap: 12px;

  &Item {
    display: flex;
    align-items: center;
    column-gap: 4px;
  }
}
