.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
}

.container {
  background-color: var(--color-bright-secondary);
  border: 1px solid var(--color-bright-tertiary);
  border-radius: 10px;
  height: 20px;
  max-width: 257px;
  padding: 3px;
  position: relative;
  width: 100%;
}

.label {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
  text-align: center;
}

.percent {
  background-color: var(--color-main-default);
  border-radius: inherit;
  width: 100%;
  height: 12px;
}
