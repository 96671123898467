.container {
}

.table {
}

.form {
  display: grid;
  row-gap: 16px;
  grid-template-rows: 1fr 64px;
  height: 100%;

  &Top {
    display: grid;
    row-gap: 16px;
  }

  &Actions {
    display: flex;
    align-items: center;
    column-gap: 12px;
    border-top: 1px solid var(--color-bright-secondary);
    padding-left: 24px;
    padding-right: 24px;
  }

  &Title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.row {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.social {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  &Item {
    background-color: var(--color-bright-secondary);
    border-radius: var(--border-radius-sm);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 8px 16px;
  }

  &Nickname {
    color: var(--color-text-bright-secondary);
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

.modal {
  width: 1000px;

  &Title {
    color: var(--color-text-bright-primary);
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    margin: 0;
    width: 100%;
  }
}

.representative {
  margin-top: 8px;

  &Avatar {
    border-radius: 50%;
    display: block;
  }
}
