.modal {
  width: 1000px;
}

.title {
  color: var(--color-text-bright-primary);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.table {
  margin-bottom: 16px;
}

.row {
  &:hover {
    background-color: transparent
  }
}

.cell {
  overflow: visible;
  padding: 0;
  height: 40px;

  input {
    padding-left: 6px;
    padding-right: 6px;
    height: 40px
  }

  &Select {
    border-color: transparent;
    border: 0 !important;
  }
}
