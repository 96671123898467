.container {
  margin-top: 0;
}

.note {
  color: var(--color-text-bright-primary);
  padding-bottom: 16px;

  &Time {
    font-size: 12px;
    line-height: 16px;
  }

  &Description {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
  }
}
