.tabs {
  &List {
    border-bottom: 1px solid var(--color-bright-secondary);
    display: flex;
  }
}

.btn {
  border-bottom: 1px solid var(--color-bright-secondary);
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;

  &:not(:first-child) {
    margin-left: 16px;
  }

  &Active {
    border-bottom: 1px solid var(--color-main-default);
  }
}
