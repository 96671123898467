.modal {
  width: 1000px;
}

.row {
  background-color: var(--color-bright-secondary);
  border-bottom: 1px solid var(--color-bright-tertiary);
  border-top: 1px solid var(--color-bright-tertiary);
  cursor: pointer;
}

.cell {
  padding-left: 8px;
}
