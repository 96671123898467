.container {
  padding-top: 4px;
  padding-bottom: 8px;
}

.form {
  &Fieldset {
    border: 1px solid var(--color-bright-tertiary);
    border-radius: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr auto auto;
    column-gap: 12px;
    padding: 24px;
  }
}
