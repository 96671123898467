.jobs {
  &Top {
    width: max-content
  }

  &Label {
    font-weight: 500;
    font-size: 16px;
    margin-right: 24px;
  }

  input {
    width: 320px;
  }
}

.additionalJob {
  padding-left: 18px;
}

.fixPrice {
  background-color: var(--color-success-default);
  font-size: 10px;
  line-height: 12px;
  padding: 2px 4px;
  color: var(--color-white);
  border-radius: 40px;
  display: inline-block;
  margin-top: 8px;
}

.table {
  margin-top: 12px;
}

.autocomplete {
  min-width: 320px;

  input {
    border-radius: 12px;
    height: 40px;
  }
}
