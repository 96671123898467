.modal {
  width: 1000px;
}

.price {
  color: var(--color-text-bright-primary);
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
}

.payment {
    font-size: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--color-bright-secondary);

  &Users {
    color: var(--color-text-bright-tertiary);
    font-size: 10px;
    text-transform: uppercase;
    line-height: 16px;
  }

  &Method {
    color: var(--color-success-default);
    background-color: var(--color-success-quinary);
    font-size: 12px;
    line-height: 16px;
    border-radius: 40px;
    padding: 2px 6px;
  }

  &Price {
    color: var(--color-text-bright-primary);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &Date {
    color: var(--color-text-bright-primary);
    line-height: 12px;
  }
}

.form {
  &Wrapper {
    margin-top: 16px;
  }
}
