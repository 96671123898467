@import "@/style/mixins";

.label {
  border: 1px solid var(--color-bright-tertiary);
  color: var(--color-text-bright-tertiary);
  border-radius: 16px;
  padding: 12px;
  height: 68px;
  display: flex;
  column-gap: 12px;
  cursor: pointer;

  &Title {
    color: var(--color-text-bright-primary);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }

  &Description {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &Input {
    @include visually-hidden;
  }

  &Avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
}
