.form {
  display: grid;
  row-gap: 16px;
  grid-template-rows: 1fr 64px;
  height: 100%;

  &Actions {
    border-top: 1px solid var(--color-bright-secondary);
    padding-left: 24px;
    padding-right: 24px;
  }

  &Search {
    width: 320px;
  }
}

.autocomplete {
  min-width: 320px;

  input {
    border-radius: 12px;
    height: 40px;
  }
}

.table {
  &Row input {
    height: 40px
  }

  &Row:hover {
    background-color: transparent;
  }
}

.productInput {
  input {
    font-size: 13px;
    padding-left: 0;
    padding-right: 0;
  }
}
