.container {
}

.form {
  display: grid;
  row-gap: 16px;

  &Top {
    margin-bottom: 8px;
  }

  &Fieldset {
    border: 1px solid var(--color-bright-tertiary);
    border-radius: 12px;
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 24px;
  }

  &Title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }
}

.section {
  margin-top: 8px;
}

.expandedSection {
  margin-top: 4px;
}

.content {
  margin-top: 4px;
}

.readMoreBtn {
  justify-content: start;
}

.footer {
  margin-top: 12px;
}
