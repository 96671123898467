.switch {
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  column-gap: 8px;
  user-select: none;

  &Handle {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  &Box {
    align-items: center;
    background-color: transparent;
    border: thin solid var(--color-bright-quaternary);
    border-radius: 1rem;
    display: inline-flex;
    flex-shrink: 0;
    height: 24px;
    transition: background-color 0.2s, border-color 0.2s;
    width: 40px;

    &::before {
      background-color: var(--color-bright-quaternary);
      border: thin solid var(--color-bright-quaternary);
      border-radius: 50%;
      box-sizing: border-box;
      content: "";
      display: inline-block;
      height: 20px;
      transform: translateX(1px);
      transition: transform 0.1s, border-color 0.1s;
      width: 20px;
    }

    &-align {
      &-start {
        order: -1;
      }

      &-end {
        order: initial;
      }
    }
  }

  &Handle:checked ~ &Box {
    background-color: var(--color-main-default);
    border-color: var(--color-main-default);

    &::before {
      background-color: var(--color-white);
      border-color: var(--color-white);
      transform: translateX(17px);
    }
  }

  &:hover &Handle:checked ~ &Box,
  &Handle:checked:focus-visible ~ &Box {
    background-color: var(--color-main-active);
    border-color: var(--color-main-active);
  }

  &Value {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--color-text-bright-primary);
  }
}
