.row {
  border-bottom: 1px solid #F2F2F3;
  color: var(--color-text-bright-quaternary);
  font-size: 10px;
}

.td {
  border-right: 1px solid #F2F2F3;
  position: relative;
  flex: 1;
  padding: 12px;
  margin-left: -1px;
  min-width: 50px;

  &First {
    flex: 2;
  }

  &Month {
    min-width: 70px;
    max-width: 70px;
    flex-basis: 100%;
  }
}
